<template>
    <div style="width: 100%">
        <!-- GENERAL -->
        <form-panel>
            <form-one-col-row v-if="form.translations">
                <!-- NAME -->
                <trans-textarea-input
                    v-model="form.translations"
                    v-tab-error:general
                    validators="required"
                    field="name"
                    name="name"
                    :error-messages="
                        errors.collect(
                            'translations' + [currentLocale] + '.name'
                        )
                    "
                    label="labels.name"
                ></trans-textarea-input>
                <trans-textarea-input
                    v-model="form.translations"
                    v-tab-error:general
                    validators="space"
                    field="searchKeywords"
                    name="searchKeywords"
                    :error-messages="errors.collect('searchKeywords')"
                    label="labels.description"
                ></trans-textarea-input>
            </form-one-col-row>
            <form-two-col-row>
                <template v-slot:col1>
                    <entity-select-input
                        v-model="form.tagGroup"
                        v-validate.immediate="'required'"
                        v-tab-error:general
                        label="labels.tag_group"
                        :entity="selectApiClientType.RESTAURANT_TAG_GROUPS"
                        :query-params="{ locale: currentLocale }"
                        name="tagGroup"
                        autocomplete
                        :error-messages="errors.collect('tagGroup')"
                    ></entity-select-input>
                </template>
            </form-two-col-row>
            <form-two-col-row>
                <template v-slot:col1>
                    <!-- ACTIVE -->
                    <checkbox-input
                        v-model="form.isFeatured"
                        v-tab-error:general
                        label="labels.featured"
                        name="isFeatured"
                        :error-messages="errors.collect('isFeatured')"
                    ></checkbox-input>
                </template>
            </form-two-col-row>
        </form-panel>
        <form-panel :title="$t('labels.images')">
            <form-one-col-row>
                <!-- IMAGE -->
                <image-editor
                    v-model="form"
                    v-tab-error:general
                    v-validate.immediate="''"
                    primary-key="image"
                    primary-label="labels.image"
                    :primary-aspect-ratio="1"
                    :primary-size="{ width: 325, height: 325 }"
                    name="image"
                    :error-messages="errors.collect('image')"
                ></image-editor>
            </form-one-col-row>
        </form-panel>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AuthMixin from '@/components/mixins/AuthMixin'
import SelectApiClientType from '@/api/SelectApiClientType'
import configType from '@/store/type/configType'
import FormPartMixin from '@/components/mixins/FormPartMixin'
import PhoneNumberMixin from '@/components/mixins/PhoneNumberMixin'
import FormTabMixin from '@/components/mixins/FormTabMixin'
import FormPanel from '@/components/form/FormPanel'
import FormOneColRow from '@/components/form/FormOneColRow'
import TransTextareaInput from '@/components/form/Inputs/TransTextareaInput'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import ImageEditor from '@/components/pages/meals/ImageEditor'

export default {
    components: {
        ImageEditor,
        FormPanel,
        FormOneColRow,
        FormTwoColRow,
        TransTextareaInput,
        EntitySelectInput,
        CheckboxInput,
    },
    mixins: [FormPartMixin, AuthMixin, PhoneNumberMixin, FormTabMixin],

    data() {
        return {
            selectApiClientType: SelectApiClientType,
        }
    },

    computed: {
        ...mapGetters({
            currentLocale: configType.getters.CURRENT_LOCALE,
        }),
    },
}
</script>
