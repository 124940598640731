<script>
import Cropper from '@/components/elements/Cropper'
import ImageUploadInput from '@/components/form/Inputs/ImageUploadInput'
import ErrorMsgInputMixin from '@/components/form/Inputs/InputMixins/ErrorMsgInputMixin'
import MediaApiClient from '@/api/RestApi/MediaApiClient'
import Notification from '@/services/Notification/Notification'
import ConfirmModal from '@/components/confirm/ConfirmModal'

export default {
    name: 'ImageEditor',
    components: {
        ConfirmModal,
        ImageUploadInput,
        Cropper,
    },
    mixins: [ErrorMsgInputMixin],
    props: {
        value: Object,
        primaryKey: String,
        secondaryKey: String,
        primaryLabel: String,
        secondaryLabel: String,
        primaryAspectRatio: Number,
        secondaryAspectRatio: Number,
        primarySize: Object,
        secondarySize: Object,
    },
    data() {
        return {
            loadingPrimary: false,
            loadingSecondary: false,
            file: null,
            primaryFile: null,
            secondaryFile: null,
            primaryBackup: null,
            secondaryBackup: null,
            imageHeight: 129,
            requestErrors: [],
            confirm: false,
        }
    },
    computed: {
        loading() {
            return this.loadingPrimary || this.loadingSecondary
        },
        primaryError() {
            return !!this.requestErrors?.find(
                (error) => error.type === 'primary'
            )
        },
        secondaryError() {
            return !!this.requestErrors?.find(
                (error) => error.type === 'secondary'
            )
        },
        primaryImg() {
            return this.value?.[this.primaryKey]?.public_url
        },
        secondaryImg() {
            return this.value?.[this.secondaryKey]?.public_url
        },
        errorCollection() {
            return [
                ...this.requestErrors
                    .map((requestError) => requestError.errors)
                    .flat(1),
                ...this.errorMessages,
            ]
        },
    },
    methods: {
        removeError(type) {
            if (this.requestErrors.length) {
                let index = this.requestErrors.findIndex((error) => {
                    return error.type === type
                })
                if (index >= 0) this.requestErrors.splice(index, 1)
            }
        },
        submit() {
            this.removeError('not_uploaded')
            if (this.primaryFile) {
                this.loadingPrimary = true
                this.upload(this.primaryFile, 'primary')
                    .then((primary) => {
                        this.$emit('input', {
                            ...this.value,
                            ...{ [this.primaryKey]: primary },
                        })

                        this.$nextTick(() => {
                            this.primaryFile = null
                        })
                    })
                    .finally(() => {
                        this.$nextTick(() => {
                            this.loadingPrimary = false
                        })
                    })
            }
            if (this.secondaryFile) {
                this.loadingSecondary = true
                this.upload(this.secondaryFile, 'secondary')
                    .then((secondary) => {
                        this.$emit('input', {
                            ...this.value,
                            ...{ [this.secondaryKey]: secondary },
                        })

                        this.$nextTick(() => {
                            this.secondaryFile = null
                        })
                    })
                    .finally(() => {
                        this.$nextTick(() => {
                            this.loadingSecondary = false
                        })
                    })
            }
        },
        upload(file, type) {
            return new Promise((resolve, reject) => {
                MediaApiClient.upload(file)
                    .then((media) => {
                        resolve(media)
                    })
                    .catch((reason) => {
                        if (!reason.response || reason.response.status >= 500)
                            Notification.requestError(reason)

                        if (reason.response && reason.response.data) {
                            reason.response.data.type = type
                            this.requestErrors.push(reason.response.data)
                        } else this.requestErrors = []

                        reject(reason)
                    })
            })
        },
        setFiles() {
            this.primaryFile = this.file
            this.secondaryFile = this.file
        },
        clear() {
            this.$refs.uploader.clear()
            this.requestErrors = []
            this.setFiles()
        },
        onClickOutside() {
            if (
                (this.primaryFile || this.secondaryFile) &&
                !this.requestErrors.find(
                    (error) => error.type === 'not_uploaded'
                )
            ) {
                this.requestErrors.push({
                    type: 'not_uploaded',
                    errors: [this.$t('labels.please_upload')],
                })
            }
        },
        deleteFiles() {
            this.clear()
            this.$emit('input', {
                ...this.value,
                ...{ [this.primaryKey]: null, [this.secondaryKey]: null },
            })
        },
    },
}
</script>

<template lang="pug">
    .image-editor(v-click-outside="onClickOutside")
        .d-flex
            .d-flex.flex-column(v-if="primaryKey" :class="secondaryKey ? 'w-50' : 'w-100'")
                label {{$t(primaryLabel)}}
                v-img(v-if="!primaryFile" :src="primaryImg" :key="primaryImg" content-class="bg-g-ef" lazy-src="/img/no-image.svg" :height="imageHeight" contain)
                cropper(v-else :file.sync="primaryFile" :size="primarySize" :error="primaryError" @update:file="removeError('primary')" :components-height="imageHeight" :aspect-ratio="primaryAspectRatio")
            .d-flex.flex-column(v-if="secondaryKey" :class="primaryKey ? 'w-50' : 'w-100'")
                label {{$t(secondaryLabel)}}
                v-img(v-if="!secondaryFile" :src="secondaryImg" :key="secondaryImg" content-class="bg-g-ef" lazy-src="/img/no-image.svg" :height="imageHeight" contain)
                cropper(v-else :file.sync="secondaryFile" :size="secondarySize" :error="secondaryError" @update:file="removeError('secondary')" :components-height="imageHeight" :aspect-ratio="secondaryAspectRatio")
        image-upload-input.mt-3(
            v-model="file"
            ref="uploader"
            @input="setFiles"
            :loading="loading"
            :error-messages="errorCollection"
            :show-image="false"
            no-upload
            only-drag)
            v-btn.mr-2(slot="buttons" color="error" @click="() => confirm = true" :disabled="!(primaryImg && secondaryImg)") {{$t('actions.delete')}}
            v-btn.mr-2(slot="cancel" color="g-e8" @click="clear" :disabled="!(primaryFile && secondaryFile)") {{$t('actions.cancel')}}
            v-btn(slot="upload" color="g-e8" @click="submit" :disabled="!(primaryFile && secondaryFile)") {{$t('actions.upload_photo')}}
        confirm-modal(v-model="confirm" @onApprove="deleteFiles")
</template>

<style lang="scss">
.image-editor {
    .v-image__image {
        z-index: 1;
        &.v-image__image--preload {
            z-index: 1;
            filter: blur(0px);
            background-size: 15%;
        }
    }
}
</style>
